import React from "react"
import { Layout } from "../components/Layout"
import {
  SectionDivider,
  SectionDividerMobile,
  ServiceSection,
  DemoButton,
  LearnMoreButton,
  GetStartedButton,
  CountsSection,
  Testimonials,
  Faq,
  AutoScrollBanner
} from "../commonComponents"
import {
  IntegrationDarkSection
} from "../integrationsUtils"
import { Helmet } from "react-helmet"

export default function GamingSupportAI() {
  return (
    <Layout
      keywords="gaming ai agent assist, gaming chatbot, game chatbot, gaming conversational ai"
      title="Best AI Agent Assist and Chatbot for Gaming Companies | IrisAgent"
      description="Transform in-game customer experiences with AI-powered agile, effective and human-like automated responses."
    >
      <Helmet htmlAttributes={{ lang: 'en' }}>
        {" "}
        <link
          rel="canonical"
          href="https://irisagent.com/gaming-support-ai/"
        />
      </Helmet>
      {/* <!-- ======= Hero Section ======= --> */}
      <section
        id="hero-new-design"
        className="agent-assistant-section-padding-top"
      >
        <div className="row" style={{ width: "100%", margin: 0 }}>
          <div
            className="col-md-6 section-padding-left"
            style={{ paddingLeft: "0" }}
          >
            <h1 className="title-support-new-design">
              Transform Gaming customer experiences with <span style={{ color: "#EF5DA8", fontWeight: 600 }}>smart AI responses</span>
            </h1>
            <div className="description-support-new-design">
            <ul className="checkbox-list">
              <li style={{ paddingLeft: "20px" }}> Automate up to 60% of interactions by <br/>delivering 24X7 support to your players</li>
              <li style={{ paddingLeft: "20px" }}> Enable virtual assistance from gameplay support<br/> to account management with GenAI chatbots</li>
              <li style={{ paddingLeft: "20px" }}> Assist player support teams with relevant information</li>
            </ul>
            </div>
            <br />
            <div className="description-button-support-new-design">
              <LearnMoreButton

            label="TRY NOW FOR FREE"
            to="/try-for-free"
          />
            </div>
          </div>
          <div className="col-md-6" style={{ padding: 0 }}>
            <div className="gaming-background"></div>
          </div>
        </div>
      </section>
      {/* <!-- End Hero --> */}

      <AutoScrollBanner />

{/*  ======= Cta Section =======  */}
      <IntegrationDarkSection
        label={
          <span className="integration-dark-section-title">
            Try{" "}
            <span className="title-fucsia-color">IrisGPT</span> on your own data for free
          </span>
        }
        button={
          <LearnMoreButton

            label="TRY NOW FOR FREE"
            to="/try-for-free"
          />
        }
        firstColClassName="col-md-8"
        secondColClassName="col-md-4"
        buttonWebFlexAlignment="end"
      />
      {/*  End Cta Section  */}
      <section
        id="about"
        className="about auto-detect-section auto-detect-section-less-top section-padding-right"
        style={{ marginBottom: "5%", marginLeft: "4%", marginTop: "3%" }}
      >
        <div className="row">
          <div className="col-md-12" style={{ display: "flex", width: "100%" }}>
            <SectionDividerMobile color="#7879F1"></SectionDividerMobile>
            <div
              className="auto-detect-img-not-mobile"
              style={{
                width: "40%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src="/img/gaming_workflows.png"
                style={{ width: "88%", margin: "10%" }}
                alt="gaming workflows"
              />
            </div>
            <SectionDivider color="#7879F1"></SectionDivider>
            <div
              className="auto-detect-info-container-50"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <div className="auto-detect-subcontainer-mobile">
                <div className="mobile-view-new-design-display">
                  <img
                    src="/img/gaming_workflows.png"
                    alt="gaming workflows"
                    style={{ width: "88%", margin: "10%" }}
                  />
                </div>
                <h2
                  className="section-title-new-design auto-detect-title agent-assistant-auto-detect-title"
                  style={{ marginBottom: "5%" }}
                >
                <span>
                  AI-powered automated workflows for your gaming interactions
                 </span>
                 </h2>
                  <div className="generic-text"
                  style={{ marginBottom: "5%" }}
                  >
                  <span>
                  <ul className="checkbox-list">
                    <li style={{ paddingLeft: "20px" }}><a href="https://irisagent.com/support-agent-assist/">IrisGPT GenAI chatbot</a> using LLMs efficiently responds to 60%+ queries with human-like responses.</li>
                    <li style={{ paddingLeft: "20px" }}>Automate tasks like account recovery, bug reporting, player support, etc. </li>
                    <li style={{ paddingLeft: "20px" }}> Automated workflows with AI driven intent detection to efficiently route and resolve issues faster. No more inaccurate manual monitoring and routing for inbound enquiries! </li>

                   </ul>
                  </span>
                  </div>
                <div style={{ display: "flex" }}>
                  <DemoButton />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End About Auto-detected incidents Section --> */}
      {/* <!-- ======= About Similar Cases Section ======= --> */}
      <section
        id="about"
        className="about auto-detect-section auto-detect-section-less-top section-padding-left-mobile section-padding-right"
        style={{ marginBottom: "5%" }}
      >
        <div className="row">
          <div className="col-md-12" style={{ display: "flex", width: "100%" }}>
            <SectionDividerMobile color="#EF5DA8"></SectionDividerMobile>
            <div
              className="auto-detect-info-container-40"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div>
                <div className="mobile-view-new-design-display">
                  <img
                    src="/img/gaming_sentiment.png"
                    style={{
                      width: "94%",
                      margin: "10%",
                    }}
                    alt="about section"
                  />
                </div>
                <div
                  className="section-title-new-design auto-detect-title"
                  style={{ marginBottom: "5%" }}
                >
                  <h2
                    className="section-title-new-design auto-detect-title"
                    style={{ marginBottom: "5%" }}
                  >
                    <span>
                      Empower support teams and prevent escalations with real-time sentiment analysis
                      <br />
                    </span>
                    </h2>
                    <div className="generic-text"
                    style={{ marginBottom: "5%" }}
                    >
                    <span>
                    <ul className="checkbox-list">
                      <li style={{ paddingLeft: "20px" }}>Give agents real-time insights from knowledge bases, and historical player and ticketing data</li>
                      <li style={{ paddingLeft: "20px" }}> Discover at-risk player tickets with automated customer sentiment impact analysis </li>
                      <li style={{ paddingLeft: "20px" }}> Get real-time alerts about trending product and customer experience issues. Custom-made and fine-tuned for gaming companies. </li>
                    </ul>
                    </span>
                    </div>
                </div>
                <div style={{ display: "flex" }}>
                  <GetStartedButton />
                </div>
              </div>
            </div>
            <SectionDivider color="#EF5DA8" flipped={true}></SectionDivider>
            <div
              className="auto-detect-img-not-mobile"
              style={{
                width: "50%",
                display: "flex",
                alignItems: "baseline",
                justifyContent: "flex-start",
              }}
            >
              <img
                src="/img/gaming_sentiment.png"
                style={{
                  width: "90%",
                  margin: "10%"
                }}
                alt="about section"
              />
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End About Similar Cases Section --> */}


      {/* <!-- ======= Counts Section ======= --> */}
      <CountsSection theme="light" />
      {/* <!-- End Counts Section --> */}

                          <section
        className="d-flex align-items-center sub-section-3-container"
        // style={{ margin: "100px" }}
      >
        <div className="">
          <div className="row">
            <div className="col-sm order-1 order-lg-1">
              <div style={{ marginLeft: "40px" }}>
                <p className="sub-section-3-h1">Any questions?</p>
                <p className="sub-section-3-h2"> We got you.</p>
                <img
                  src="/img/security-5.svg"
                  className="img-fluid"
                  alt="secure"
                />
              </div>
            </div>
            <div className="col-sm pt-5 pt-lg-0 order-2 order-lg-2 d-flex flex-column justify-content-center">
              <div id="accordion">
                <Faq
                  id="faq1"
                  question="What is Conversational AI in gaming?"
                  answer="Conversational AI in gaming uses artificial intelligence technologies, such as chatbots and virtual assistants, to facilitate interactive, human-like conversations between gaming companies and their players. These AI-powered systems understand and process natural language, enabling players to perform tasks related to their gaming experience, receive personalized support, resolve queries, and get instant help through text or voice commands. By automating customer service and enhancing user experience, conversational AI transforms how gaming companies operate and interact with their players, making gaming more accessible, efficient, and enjoyable."
                />
                <Faq
                  id="faq2"
                  question="What is Conversational gaming support?"
                  answer="Conversational gaming support is a customer service approach that utilizes chatbots, voice assistants, and messaging platforms to provide a more interactive and personalized gaming support experience. It allows players to request account assistance, access in-game information, receive gameplay advice, and get support through natural language conversations via text or voice. This method aims to make gaming more accessible and convenient, enabling players to receive the best support seamlessly in real time without navigating complex support systems or forums."
                />
                <Faq
                  id="faq3"
                  question="How can AI be used in the gaming industry?"
                  answer="AI enhances customer service, improves security, and streamlines operations in the gaming industry. It enables personalized player support through chatbots, detects fraudulent activities with machine learning algorithms, and automates routine support tasks for quicker resolution times. AI also optimizes back-office processes like player data analysis and risk management, providing insights for better decision-making. Integrating AI in gaming leads to more efficient operations, reduced costs, and an improved player experience, making gaming more accessible, secure, and tailored to individual needs."
                />
                <Faq
                  id="faq4"
                  question="How is ChatGPT used in the gaming industry?"
                  answer="ChatGPT provides automated customer support, handles inquiries, and offers personalized player advice through natural language processing. It can answer questions, assist players with account issues, guide users through gameplay challenges, and offer support for in-game purchases, enhancing the player experience with its conversational abilities. Gaming companies also utilize ChatGPT for internal purposes, such as training staff or automating routine tasks, improving operational efficiency, and reducing the workload on human employees."
                />
                <Faq
                  id="faq5"
                  question="Can Conversational AI handle complex player requests?"
                  answer="Advanced conversational AI systems can handle complex player requests, such as account recovery, gameplay troubleshooting, in-game purchases, or applying for game-related refunds, by securely processing user requests and integrating with the organization’s knowledge systems."
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- ======= Services Section ======= --> */}
      <ServiceSection />
      {/* <!-- End Services Section --> */}



      {/* <!-- ======= Contact Services Section ======= --> */}
      {/* <ContactSection /> */}
      {/* <!-- End Contact Section --> */}
    </Layout>
  )
}
// todo: move to commonComponents as Security page also uses it
const SecurityCard = ({ title, body, image }: any) => {
  return (
    <div
      className="col-sm order-1 order-lg-1 "
      style={{ textAlign: "center", marginLeft: "10px", marginRight: "10px" }}
    >
      <img src={image} className=" sub-section-2-img" alt="secure" />
      <h4 className="sub-section-2-h">{title}</h4>
      <p className="sub-section-2-p">{body}</p>
    </div>
  )
}
